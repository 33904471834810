import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Container from '../components/Container';

import loadComponents from '../components/Loadable';

const BlogLayout = loadComponents('blog-layout');
const SubpageHeader = loadComponents('subpage-header');

const { BlogTitle, BlogUrl } = require('../utils/constants');

export default function BlogPage({ location, data }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout
      title={BlogTitle}
      location={BlogUrl}
      prevPage={prevPage}
      padding="0 0 60px"
    >
      <article>
        <SubpageHeader title={BlogTitle} />
        <Container>
          <BlogLayout allWpPost={data.allWpPost} />
        </Container>
      </article>
    </Layout>
  );
}

export const BlogQuery = graphql`
  query BlogHomeQuery {
    allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          excerpt
          ...PostData
          ...BlogThumbnail
        }
      }
    }
  }
`;
